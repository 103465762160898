import React from 'react'
import slider from "../Images/Free-Consultation-icon.png"
import { Link } from "react-router-dom"

const StickySlider = () => {
  return (
    <div className="stickySlider">
        <Link to="/contact-us">
            <img src={slider} alt="consultation"/>
        </Link>
    </div>
  )
}

export default StickySlider
