import React, { useEffect } from 'react'
import img_1 from '../Images/img-1.jpeg'
import img_2 from '../Images/img-2.jpeg'
import img_4 from '../Images/img-4.jpeg'
import img_5 from '../Images/img-5.jpeg'
import img_6 from '../Images/img-6.jpeg'
import img_7 from '../Images/img-7.jpeg'
import img_9 from '../Images/img-9.jpeg'
import img_10 from '../Images/img-10.jpeg'
import img_13 from '../Images/img-13.jpeg'
import img_14 from '../Images/img-14.jpeg'
import img_15 from '../Images/img-15.jpeg'
import img_16 from '../Images/img-16.jpeg'
import img_17 from '../Images/img-17.jpeg'
import img_18 from '../Images/img-18.jpeg'
import img_19 from '../Images/img-19.jpeg'

const images = [
    img_1, img_2, img_4, img_5, img_6, img_7, img_9, img_10, img_13, img_14, img_15, img_16, img_17, img_18, img_19
]

const ImageGallery = (props) => {
    useEffect(() => {
        document.title = `Dynamic Security - ${props.title}`
    }, [props.title])
    return (
        <div className="body">
            <h2 className='newsHeading'>Our Photo Gallery</h2>
            <div className="row">
                {images.map((img, index) => {
                    return <div key={index} className="col-md-4">
                        <div className="thumbnail">
                            <img src={img} alt="Lights" style={{ 'width': '100%' }} />
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default ImageGallery
