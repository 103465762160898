import React, { useEffect } from 'react'
import profileFile from '../Images/DYNAMIC SECURITY PROFILE.pdf'

const Guards = (props) => {
  useEffect(() => {
    document.title = `Dynamic Security - ${props.title}`
  }, [props.title])

  return (
    <div className='body'>
      <h2 className="newsHeading">Our Guards</h2>
      <div>
        <h2>WE HIRE THE BEST</h2>
        <p>DYNAMIC SECURITY (PVT) Ltd. make sure each candidate on duty at your property is fully qualified and trained highly standard to ensure the safety and protection of your property, assets and people. With our extensive pre-hiring process, all candidates are put through a vigorous procedure making sure each guard is courteous, professional and have passed pre-screened background checks that include drug testing to ensure we find the most suitable candidate for the position is dedicated to premiere, world-class security services. Through our "training procedure" we makes sure every guard is fully equipped, knowledgeable on State Rules and Regulations as well as firearm certified to handle any situation. Having a properly trained security guard on your premises can lower the risk of liability, protect your business during and after hours as well as give you a peace of mind that your property is always protected. This training includes the following:</p>
        <ul className='font'>
          <li>General Training/Real Life </li>
          <li>Criminal Code of Procedures and Laws </li>
          <li>Report Writing/Note Taking and Observation</li>
          <li>Emergency Response Situations </li>
          <li>Fire Hazards </li>
          <li>Security Guard Authority </li>
          <li>Conflict Resolution/De-escalation</li>
          <li>Radio Procedures </li>
          <li>Bomb Threats </li>
          <li>Firearms Training and Range Qualification</li>
        </ul>
        <h2>PREMIUM AND COMMERCIAL SECURITY GUARDS BY DYNAMIC SECURITY</h2>
        <p>Organization Security Whatever the organization, Dynamic Security has the professionally trained staff to provide the client with the best innovations in solutions. Our company will determine how to minimize security threats and will help and organization preserve the security of any environment. A company can ensure all employees of their safety with the quality security guard service supplied by Dynamic Security. Our security company provides armed security, physical security, electronic security, security jobs and other security solutions for businesses, organizations and individuals. The maximum in security protection is supplied to keep assets and workers safe. Our supervisors are carefully chosen based on their level of professionalism, mannerisms, aptitude and skill. All of our supervisors and guards endure complete and thorough background checks, criminal record and identity checks. Our intense preparation and training encompasses hypothetical and practical coursework that focus on:</p>
        <ul className='font'>
          <li>Business Security Law </li>
          <li>Business Security services and Strategies </li>
          <li>Fire Safety and Fire Safety Guards </li>
          <li>First Aid and Search and Rescue </li>
          <li>Organization Security Law </li>
          <li>Organization Security Services </li>
          <li>Professional Security Service Implementations </li>
          <li>Public Decorum and Professionalism </li>
          <li>Security Services and Public Relations </li>
          <li>Surveillance Techniques </li>
          <li>Tact and Diplomacy in Delicate Security Situations </li>
        </ul>
        <p>Dynamic Security supervisors are comprised of law enforcement professionals and individuals with extensive military backgrounds. All supervisors are micromanaged and are expected to maintain weekly recorded logs and procedure precise incident reports and documentation.</p>
        <a className='btn btn-secondary' href={profileFile} download>Download Profile File</a>
      </div>
    </div>
  )
}

export default Guards
