import { Route, Routes } from "react-router-dom"
import Footer from './Components/Footer';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import StickySlider from './Components/StickySlider';
import ContactPage from './Components/ContactPage';
import CompanyProfile from "./Components/CompanyProfile";
import Services from "./Components/Services";
import Guards from "./Components/Guards";
import ImageGallery from "./Components/ImageGallery";
import WorkTeam from "./Components/WorkTeam";

function App() {

  return (
    <div>
      <StickySlider />
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home title="Home" />} />
        <Route path='/contact-us' element={<ContactPage title="Contact Page" />} />
        <Route path='/company-profile' element={<CompanyProfile title="Company Profile" />} />
        <Route path='/our-services' element={<Services title="Services" />} />
        <Route path='/our-guards' element={<Guards title="Guards" />} />
        <Route path='/work-team' element={<WorkTeam title="Work Team" />} />
        <Route path='/photo-gallery' element={<ImageGallery title="Photo Gallery" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
