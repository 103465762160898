import React, { useState, useEffect } from 'react';
import firstImg from "../Images/slider-img-1.jpeg";
import secondImg from "../Images/slider-img-2.jpeg";
import thirdImg from "../Images/slider-img-3.jpeg";
import fourthImg from "../Images/slider-img-4.jpeg";
import fifthImg from "../Images/slider-img-5.jpeg";
import sixthImg from "../Images/img-7.jpeg"

const slideImages = [
  {
    url: firstImg,
  },
  {
    url: secondImg,
  },
  {
    url: thirdImg,
  },
  {
    url: fourthImg,
  },
  {
    url: fifthImg,
  },
  {
    url: sixthImg,
  }
];

const ImageSlider = () => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 3000);
    return () => clearInterval(slideInterval)
  }, [])

  const nextSlide = () => {
    setCurrent((current) => (current === slideImages.length - 1 ? 0 : current + 1));
  };

  const prevSlide = () => {
    setCurrent((current) => (current === 0 ? slideImages.length - 1 : current - 1));
  };

  return (
    <section className='slider'>
      <i className="fa-solid fa-circle-arrow-left left-arrow" onClick={prevSlide} />
      <i className="fa-solid fa-circle-arrow-right right-arrow" onClick={nextSlide} />
      {slideImages.map((slide, index) => {
        return (
          <div
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          >
            {index === current && (
              <img src={slide.url} alt='slide' className='image' />
            )}
          </div>
        );
      })}
    </section>
  );
}

export default ImageSlider
