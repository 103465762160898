import React, { useState } from 'react'
import logoName from "../Images/DS_NAME.jpg"
import logo from "../Images/DS_NLOGO.jpg"
import { Link } from "react-router-dom"

const Navbar = () => {
    const [isHamTrue, setIsHamTrue] = useState(true)
    const [isCrossTrue, setIsCrossTrue] = useState(false)

    const handleHamBurger = () => {
        setIsHamTrue(!isHamTrue)
        setIsCrossTrue(!isCrossTrue)
    }

    const handleCross = () => {
        setIsHamTrue(!isHamTrue)
        setIsCrossTrue(!isCrossTrue)
    }

    return (
        <div className="header">
            <div className='responsiveNavBar'>
                {isHamTrue && <i style={{'color': '#fff'}} className="fa-solid fa-bars fa-xl" onClick={handleHamBurger} />}
                {isCrossTrue && <i style={{'color': '#fff'}} className="fa-solid fa-xmark fa-2xl" onClick={handleCross} />}
                <img src={logo} className='ms-3' alt="main_logo" id='responsiveLogo' />
                <img src={logoName} alt="logo_name" id='responsiveLogoName' />
                <div className={isCrossTrue ? 'sideBar left' : 'sideBar'} >
                    <ul className="responsiveNavBarList">
                        <Link to="/"><li onClick={handleCross}>Home</li></Link>
                        <Link to="/company-profile"><li onClick={handleCross}>Company Profile</li></Link>
                        <Link to="/our-services"><li onClick={handleCross}>Our Services</li></Link>
                        <Link to="/our-guards"><li onClick={handleCross}>Our Guards</li></Link>
                        <Link to="/work-team"><li onClick={handleCross}>Our Team</li></Link>
                        <Link to="/photo-gallery"><li onClick={handleCross}>Photo Gallery</li></Link>
                        <Link to="/contact-us"><li onClick={handleCross}>Contact Us</li></Link>
                        <li style={{ 'listStyle': 'none', 'color': '#fff' }}>
                            Media Links
                            <br />
                        <a href="https://www.facebook.com/profile.php?id=100025310341985/"><i className="fa-brands fa-facebook mx-2" /></a>
                        <a href="https://wa.me/923002173800"><i className="fa-brands fa-whatsapp" /></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="autoDiv">
                <span>
                    <img src={logo} id='logo' alt="logo" />
                    <img src={logoName} id="logoName" alt="logoName" />
                </span>
                <div className="innerBox">
                    <div className="quickLinks my-3">
                        <span>Media Links</span>
                        <a href="https://www.facebook.com/profile.php?id=100025310341985/"><i className="fa-brands fa-facebook mx-2" /></a>
                        <a href="https://wa.me/923002173800"><i className="fa-brands fa-whatsapp" /></a>
                    </div>
                    <span className="demoNum">+92 322-2000185</span>
                </div>
            </div>
            <div className="navBar">
                <ul className="navBarList">
                    <Link to="/"><li>Home</li></Link>
                    <Link to="/company-profile"><li>Company Profile</li></Link>
                    <Link to="/our-services"><li>Our Services</li></Link>
                    <Link to="/our-guards"><li>Our Guards</li></Link>
                    <Link to="/work-team"><li>Our Team</li></Link>
                    <Link to="/photo-gallery"><li>Photo Gallery</li></Link>
                    <Link to="/contact-us"><li>Contact Us</li></Link>
                </ul>
            </div>
        </div>
    )
}

export default Navbar
