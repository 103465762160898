import React, { useEffect } from 'react'
import profileFile from '../Images/DYNAMIC SECURITY PROFILE.pdf'

const Services = (props) => {
  useEffect(() => {
    document.title = `Dynamic Security - ${props.title}`
  }, [props.title])

  return (
    <div className='body servicesBlock'>
      <h2 className="newsHeading">Our Services</h2>
      <div>
        <h2>BODY GUARDS & EXECUTIVE PROTECTION SERVICES</h2>
        <p>Dynamic Security understands the personal needs of individuals requiring protection services. The world is a place where the need for protection has increased, personal security and executive protection helps to keep important people safe at all times, no matter what industry the individual is employed in. VIPs protection and executive protection is offered to individuals that have legitimate concerns based on real world threats. Our officers can address all security service needs in a professional and tactful manner. <br /> Our Body guards are well trained in discipline, allowing us to offer quality protection services with ease. We work in many parts of the nation and offer executives protection through quality security services, as well as providing executive protection to public figures and private individuals. <br /> Our VIP protection Services include:</p>
        <ul className='font'>
          <li>Anti-Terrorist Security</li>
          <li>Armed Body Guards</li>
          <li>Armed Chauffeurs</li>
          <li>Armed Guards</li>
          <li>Concierge and Reception Security Services</li>
          <li>Executive Protection Service</li>
          <li>Fire safety Directors and Fire Guards</li>
          <li>International Protection</li>
          <li>Security Patrol</li>
          <li>Special Event Security Services</li>
          <li>Unarmed Security Guard Patrol Services</li>
          <li>VIP Protection</li>
        </ul>
        <h2>SPECIAL EVENTS & CONFERENCE SECURITY</h2>
        <h3>Information for today's Event Planners</h3>
        <p>Today, security services are a vital component for all high profile figures and families and prestigious social and business affairs. Dynamic Security is proud to provide special security services specifically designed to supply effective, professional security for special events. Our services include protection for private clients, executive protection and VIPs protection, as well as security services for both businesses and organizations. Our event safety & conference security service is constructed upon International Security methodology: crowd profiling and physical security management is implemented to ensure maximum security measures are enforced. We believe that extensive planning, effective communication, and tactful responses to security issues are a prerequisite when it comes to professional security measure implementation.</p>
        <h2>CLOSED CIRCUIT TELEVISION (CCTV)</h2>
        <p>An invaluable asset in the world of security is the ability to use CCTV (Closed Circuit TV) to conduct surveillance. Using a CCTV system, individuals, companies and organizations can instantly reduce or even cease the amount of loss they experience due to theft. Retailers rely on CCTV to monitor their shops, stores, employees and shoppers: the effect is an incredible and dramatic reduction as a direct result in the innovative loss prevention method that CCTV provides. Video surveillance proves vital when it comes to litigation, especially in the face of the number of bogus claims and theft that occur each and every year. Often times CCTV surveillance proves to be Just the piece of evidence that completes a case: it's extremely hard to dispute evidence that is caught on video. Some CCTV systems can record to DM or video cassettes and be used later in a court of law. In contrast, institutions like hospitals or even companies that have warehouses and large amounts of storage rely on CCTV to monitor activity inside the location as well as to monitor the surrounding areas of a location. In essence, CCTV is an advance in technology that serves one's security needs well: it can thwart theft and vandalism attempts and it can make areas that were once unsafe, safer to utilize. Airports are also relying on the use of CCTV to monitor everyone that enters the airport. In fact, CCTV is an imperative part of anti-terrorists security plans. Monitoring the inside and outside of airports is a major move in counter terrorism efforts. Through the use of CCTV the individual, business, or organization can partake of virtual patrolling opportunities. Rather than having a security patrol company monitor a location, the CCTV can monitor a site 24 hours a day, seven days a week. This minimizes the cost of security by eliminating, in some cases, the need for security patrols or on site patrols. </p>
        <h3>Closed Circuit & Surveillance TV is best for:</h3>
        <ul className='font'>
          <li>Home Security</li>
          <li>Business Security</li>
          <li>Anti-Terrorists Security</li>
          <li>Theft Prevention </li>
          <li>Vandalism Prevention </li>
          <li>Loss Prevention</li>
          <li>Monitoring Areas that are Risky or Unsafe without Surveillance </li>
        </ul>
        <h3>Closed Circuit TV Capabilities Include:</h3>
        <ul className='font'>
          <li>CCTV Monitoring via Cellular Phones </li>
          <li>CCTV Monitoring via Landline Phones</li>
          <li>CCTV Monitoring via the Internet </li>
          <li>CCTV Monitoring via Wireless Devices </li>
          <li>Digital Observation</li>
          <li>Digital Recording</li>
          <li>Full Control of the CCTV System </li>
          <li>Playback Incident Retrieval </li>
          <li>Provides Investigative Evidence </li>
          <li>Real Time Notification of Incidents </li>
          <li>Total Protection 24 hours a day, all week long </li>
          <li>View of Enter Location of Operation </li>
          <li>Virtual Patrolling </li>
        </ul>
        <h3>The Benefits of Closed Circuit and Surveillance TV include:</h3>
        <ul className='font'>
          <li>A near immediate return on the initial investment </li>
          <li>A reduction in employee turnover </li>
          <li>Employee safety measures increase </li>
          <li>A reduction in Theft </li>
          <li>A reduction in incidents of employee fraud </li>
          <li>An increase in the effectiveness of an overall security plan </li>
          <li>Crime and fraud deterrents in place with CCTV systems in place </li>
          <li>Protection of assets </li>
          <li>Management Control Improvements</li>
        </ul>
      </div>
      <a className='btn btn-secondary' href={profileFile} download>Download Profile File</a>
    </div>
  )
}

export default Services
