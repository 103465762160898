import React from 'react'

const Footer = () => {
  return (
    <div className="footer">
      <div className='footerContactBox'>
        <span style={{'fontSize': '30px'}}>Contact</span>
        <span style={{'hyphens': 'auto', 'width': '254px'}}>Ground Floor 60/5-C Dadabhoay Nooruji Road Shikarpur Colony Karachi Pakistan</span>
        <span style={{'width': '128px'}}><i style={{'marginRight': '2%'}} className="fa-solid fa-phone"/>+92 300-9231891</span>
        <span style={{'width': '128px'}}><i style={{'marginRight': '2%'}} className="fa-solid fa-phone"/>+92 300-2173800</span>
        <span style={{'width': '179px', 'color': '#ff7200'}}><i style={{'marginRight': '2%', 'color': 'white'}} className="fa-solid fa-envelope"/>info@dynamicsecurity.pk</span>
      </div>
      <div className='footerContactBox'>
        <span style={{'fontSize': '30px'}}>Company Info</span>
        &copy; Copyright 2023 Dynamic Security PVT LTD.
      </div>
    </div>
  )
}

export default Footer
