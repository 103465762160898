import React, { useState, useEffect } from 'react'

const ContactPage = (props) => {
  useEffect(() => {
    document.title = `Dynamic Security - ${props.title}`
  }, [props.title])

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [message, setMessage] = useState('')
  // const [showAlert,setShowAlert] = useState({
  //   showSuccess: false,
  //   showError: false
  // })

  // const sendEmail = async (e) => {
  //   e.preventDefault();
  //   const request = {
  //     name,
  //     email,
  //     number,
  //     message
  //   }

  //   const res = await fetch('http://localhost:5000/sendMail', {
  //     method: 'POST',
  //     headers: {
  //       'Content-type': 'application/json'
  //     },
  //     body: JSON.stringify(request)
  //   })

  //   const data = await res;
  //   if(data.status === 200) {
  //     setShowAlert({showSuccess: true, showError: false})
  //     setTimeout(() => {
  //       setShowAlert({showSuccess: false, showError: false})
  //     }, 2000);
  //   } else if(data.status === 400) {
  //     setShowAlert({showSuccess: false, showError: true})
  //     setTimeout(() => {
  //       setShowAlert({showSuccess: false, showError: false})
  //     }, 2000);
  //   }
  //   setName('')
  //   setNumber('')
  //   setEmail('')
  //   setMessage('')
  // }
  return (
    <div className="body">
      {/* {showAlert.showSuccess && <div className="alert alert-success" role="alert">Email Sent</div>}
      {showAlert.showError && <div className="alert alert-warning" role="alert">Email Sent</div>} */}
      <h2 className="newsHeading">Contact Us</h2>
      <form>
        <div className="row mb-3">
          <label htmlFor="name" id="formName" className="col-sm-4 col-form-label">Name:</label>
          <div className="col-sm-5">
            <input type="text" name='user_name' className="form-control" id="name" value={name} onChange={(e) => { setName(e.target.value) }} />
          </div>
        </div>
        <div className="row mb-3">
          <label htmlFor="email" id="formEmail" className="col-sm-4 col-form-label">Email:</label>
          <div className="col-sm-5">
            <input type="email" name='user_email' className="form-control" id="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
          </div>
        </div>
        <div className="row mb-3">
          <label htmlFor="phoneNumber" id="formNumber" className="col-sm-4 col-form-label">Phone Number:</label>
          <div className="col-sm-5">
            <input type="number" name='user_number' className="form-control" id="phoneNumber" value={number} onChange={(e) => { setNumber(e.target.value) }} />
          </div>
        </div>
        <div className="row mb-3">
          <label htmlFor="comments" id="formComments" className="col-sm-4 col-form-label">What type of services do you need?</label>
          <div className="col-sm-5">
            <textarea className="form-control" name="message" id="comments" rows="3" value={message} onChange={(e) => { setMessage(e.target.value) }} />
          </div>
        </div>
        <a className='btn' id="submitBtn" href={`mailto:info@dynamicsecurity.pk?subject=${name}&body=${email}`}>Send</a>
        {/* <button id="submitBtn" type="submit" className="btn">Send</button> */}
      </form>
    </div>
  )
}

export default ContactPage
