import React, { useEffect } from 'react'
import ceo_img from '../Images/CEO-img.PNG'
import gm_img from '../Images/GM-img.PNG'
import profileFile from '../Images/DYNAMIC SECURITY PROFILE.pdf'

const CompanyProfile = (props) => {
  useEffect(() => {
    document.title = `Dynamic Security - ${props.title}`
  }, [props.title])

  return (
    <div className='body'>
      <h2 className="newsHeading">Company Profile</h2>
      <div>
        <h3>A MESSAGE FROM THE CEO</h3>
        <img src={ceo_img} alt='CEO_Image'></img>
        <p>Dear Client, <br /> Today's security threads require responses by professionals to ensure tht your facility does not present itself as a weak-link and a target of opportunity. Beyond guarding precious human life and valuable property. I take pride in providing a SENSE OF SECURITY, a priceless and often elusive commodity. I established y company because I was not thrilled with the quality of sequirty services that were available for hire on the market I decided to offer a better service by drawing on my experience of 20 years in the Security Services Sector. It is therefore my personal mission to ensure that we hire exceptionally qualified security personal, employ only seasoned supervisory staff and focus on clients that have reasons to be concerned about their vital interests.</p>
        <p>I trust that you understand, and appreciate the fact that premium security service cannot, and wil not, compete on the basis of the lowest bid price. I take pride in our services an dtrust that you will join a growing list of clients that have come to recognize that cost difference between exceptional security and average security is well worth the service and PEACE OF MIND that comes when DYNAMIC SECURITY is in the job.</p>
        <p>Thank you for considering DYNAMIC SECURITY (PVT) LTD.
          <br />Sincerely
          <br />
          <span style={{ 'fontWeight': 'bolder', 'fontSize': '20px' }}>Sohail Shah</span>
          <br />
          <span style={{ 'fontWeight': 'bolder', 'fontSize': '20px' }}>Cheif Executive</span>
          <br />
          <span style={{ 'fontWeight': 'bolder', 'fontSize': '20px' }}>sohail.shah@dynamicsecurity.pk</span>
        </p>
        <h3>A MESSAGE FROM THE GM</h3>
        <img src={gm_img} alt='GM_Image'></img>
        <p>
          Dear Client, <br />
          I have served in Sindh Police for past 35 years and retired as Deputy Superintendent of Police (DSP). During my service I fulfilled my duty with honesty and protected my people's life and valuables. I believe that providing protection is just not a part of my duty but it is my social responsibility to ensure the safety of all people. In consideration of these facts I am now a part of Dynamic Security (Pvt) Ltd, appointed General Manager (GM) my aim is to provide the quality security to people through which I get the chance to serve people and continue the mission of serve the country and its people.
          <br />
          Thank you for considering DYNAMIC SECURITY (PVT) LTD.
          <br />
          Sincerely.
          <br />
          <span style={{ 'fontWeight': 'bolder', 'fontSize': '20px' }}>ZAHID HUSSAIN</span>
          <br />
          <span style={{ 'fontWeight': 'bolder', 'fontSize': '20px' }}>General Manager</span>
          <br />
          <span style={{ 'fontWeight': 'bolder', 'fontSize': '16px', 'fontFamily': '"Ubuntu"' }}>zahid.hussain@dynamicsecurity.pk</span>
        </p>
        <a className='btn btn-secondary' href={profileFile} download>Download Profile File</a>
      </div>
    </div>
  )
}

export default CompanyProfile
