import React, { useEffect } from 'react'
import CEO from "../Images/CEO-img.PNG"
import GM from "../Images/GM-img.PNG"
import worker_1 from "../Images/worker-1.jpeg"
import worker_2 from "../Images/worker-2.jpeg"
import worker_3 from "../Images/worker-3.jpeg"
import worker_4 from "../Images/worker-4.jpeg"
import worker_5 from "../Images/worker-5.jpeg"

const WorkTeam = (props) => {
    useEffect(() => {
        document.title = `Dynamic Security - ${props.title}`
    }, [props.title])
    return (
        <div className='body'>
            <h2 className='newsHeading'>Our Work Team</h2>
            <div style={{'margin': '0 auto'}} className="card my-2 justify-content-center align-items-center">
                <img src={CEO} className="card-img-top imageSize" alt="CEO_img" />
                <div className="card-body text-center">
                    <h5 className="card-title">Sohail Shah</h5>
                    <span className="card-text">Chief Executive Officer</span><br />
                    <i className="fa-solid fa-envelope marginRight" />
                    <span className='smallFont'>sohail.shah@dynamicsecurity.pk</span><br />
                    <i className="fa-solid fa-phone marginRight" />
                    <span>+92 322-2000185</span>
                </div>
            </div>
            <div className='teamMembers'>
                <div className="card mx-4 my-2 justify-content-center align-items-center">
                    <img src={GM} className="card-img-top imageSize" alt="GM_img" />
                    <div className="card-body text-center">
                        <h5 className="card-title">Zahid Hussain</h5>
                        <span className="card-text">General Manager</span><br />
                        <i className="fa-solid fa-envelope marginRight" />
                        <span className='smallFont'>zahid.hussain@dynamicsecurity.pk</span><br />
                        <i className="fa-solid fa-phone marginRight" />
                        <span>+92 321-9231891</span>
                    </div>
                </div>
                <div className="card mx-4 my-2 justify-content-center align-items-center">
                    <img src={worker_5} className="card-img-top imageSize" alt="GM_img" />
                    <div className="card-body text-center">
                        <h5 className="card-title">Muhammad Khalid Butt</h5>
                        <span className="card-text">HR/Finance Manager</span><br />
                        <i className="fa-solid fa-envelope marginRight" />
                        <span className='smallFont'>khalid.butt@dynamicsecurity.pk</span><br />
                        <i className="fa-solid fa-phone marginRight" />
                        <span>+92 300-2173800</span>
                    </div>
                </div>
                <div className="card mx-4 my-2 justify-content-center align-items-center">
                    <img src={worker_2} className="card-img-top imageSize" alt="GM_img" />
                    <div className="card-body text-center">
                        <h5 className="card-title">Syed Zamam Hussain</h5>
                        <span className="card-text">Admin Manager</span><br />
                        <i className="fa-solid fa-envelope marginRight" />
                        <span className='smallFont'>zamam.hussain@dynamicsecurity.pk</span><br />
                        <i className="fa-solid fa-phone marginRight" />
                        <span>+92 344-8119493</span>
                    </div>
                </div>
                <div className="card mx-4 my-2 justify-content-center align-items-center">
                    <img src={worker_3} className="card-img-top imageSize" alt="GM_img" />
                    <div className="card-body text-center">
                        <h5 className="card-title">Tariq Mehmood</h5>
                        <span className="card-text">Legal Advisor</span><br />
                        <i className="fa-solid fa-phone marginRight" />
                        <span>+92 313-2121723</span>
                    </div>
                </div>
                <div className="card mx-4 my-2 justify-content-center align-items-center">
                    <img src={worker_1} className="card-img-top imageSize" alt="GM_img" />
                    <div className="card-body text-center">
                        <h5 className="card-title">Mushahid Raza</h5>
                        <span className="card-text">Field Supervisor</span><br />
                        <i className="fa-solid fa-phone marginRight" />
                        <span>+92 343-5291697</span>
                    </div>
                </div>
                <div className="card mx-4 my-2 justify-content-center align-items-center">
                    <img src={worker_4} className="card-img-top imageSize" alt="GM_img" />
                    <div className="card-body text-center">
                        <h5 className="card-title">Syed Anwar</h5>
                        <span className="card-text">Field Supervisor</span><br />
                        <i className="fa-solid fa-phone marginRight" />
                        <span>+92 349-2826313</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkTeam
