import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import ImageSlider from './ImageSlider'

const Home = (props) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const dateObj = new Date()
    const currDate = dateObj.getDate()
    const currMonth = monthNames[dateObj.getMonth()]
    useEffect(() => {
        document.title = `Dynamic Security - ${props.title}`
    }, [props.title])

    return (
        <div className="body">
            <div className='securityContent'>
                <ImageSlider />
                <div className="shortIntro my-3">
                    <h4>Welcome to Dynamic Security PVT LTD. </h4>
                    <p>
                        Dynamic Security is a Karachi, Pakistan based private security firm providing premier security guard and surveillance camera security solutions. We specialize in Residential and Commercial properties and are committed in to meeting and exceeding our client's needs and expectations.
                    </p>
                    <Link to='/company-profile'><button className="readMoreBtn mb-2">Read More</button></Link>
                </div>
                <div className="content">
                    <div className="newsBox">
                        <h5 className="newsHeading">News</h5>
                        <div id="news" className="mx-2">
                            {currDate}<br /><strong>{currMonth}</strong>
                        </div>
                        <div className="newsContent mx-2">
                            <h5>Upcoming news</h5>
                            <p>Dynamic Security Upcoming news</p>
                        </div>
                    </div>
                    <div className="testimonials">
                        <h5>Testimonials</h5>
                        <p>With great pleasure, I find myself writing to express my total satisfaction with Dynamic Security PVT LTD, Customer</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
